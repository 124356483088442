import {Andiv, Button, Http404Illustration, Text, palette} from '@ambler/andive-next'
import React from 'react'
import styled from 'styled-components'
import {useRouter} from 'next/router'

const Container = styled(Andiv)`
  height: 100vh;
  text-align: center;
`

const NotFound = () => {
  // Opinionated: do not record an exception in Sentry for 404
  const router = useRouter()

  return (
    <Container justify="center">
      <Andiv column justify="center" align="center" maxWidth="400px" gap="48px">
        <Http404Illustration width={200} height={200} />

        <Andiv column gap="16px">
          <Text t="h3">
            <b>Cette page est introuvable (404)</b>
          </Text>

          <Text t="body2" color={palette.amblea.grey[600]}>
            Nous sommes désolés pour la gêne occasionnée.
            <br />
            Notre équipe technoque a été prévenue et va résoudre le problème dans les plus brefs délais.
          </Text>
        </Andiv>

        <Button variant="primary" onClick={() => router.replace('/')}>
          <Button.Label>Retour au Planning</Button.Label>
        </Button>
      </Andiv>
    </Container>
  )
}

export default NotFound
